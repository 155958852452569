import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { fetchDiscountSubscriptions, setCancellationCandidate } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { PRODUCT_CODES, SUBSCRIPTION_PENDING_FAILED } from 'constants/payments';

import { cancelFlow_subscriptionScreenLoad } from 'services/analytics/trackers/cancelFlow';

import useSubscriptionsCount from 'hooks/subscriptions/useSubscriptionCount';
import { useSubscriptions, useDispatch, useCancelSubscription } from 'hooks';

import Skeleton from 'components/Skeleton';
import { Box, Text } from 'components/library';
import PaymentFailedCard from './components/SubscriptionCard/components/PaymentFailedCard';
import { SubscriptionCard, SubscriptionHelpButton } from './components';

import { ISubscription, PaymentProviders } from 'types/subscription';
import { ModalName } from 'types/modals';

const SubscriptionsTab = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isLoading, subscriptions } = useSubscriptions();
    const { onCancelSubscription: cancelByForce } = useCancelSubscription();
    const { activeCount, canceledCount } = useSubscriptionsCount(subscriptions);

    useEffect(() => {
        if (subscriptions?.length) {
            cancelFlow_subscriptionScreenLoad({
                reminder: subscriptions.some((i) => i.reminder),
                free_month: subscriptions.some((i) => i.discounts.free_month),
                subscriptions_status: subscriptions.map((i) => ({ [i.external_id]: i.state })),
            });
        }
    }, [subscriptions]);

    const onCancelSubscription = (candidate: ISubscription) => {
        const hasDiscount = Boolean(candidate.discounts.discount);
        const hasOffer = Boolean(candidate.discounts.pause || candidate.discounts.free_month || candidate.reminder);
        const shouldFetchDiscount =
            !hasDiscount && candidate.payment_provider === PaymentProviders.Solidgate && !candidate.fee?.fee_enabled;

        const isSecondSubscription =
            candidate.product_code !== PRODUCT_CODES.APP_VIP_SUPPORT &&
            candidate.product_code !== PRODUCT_CODES.APP_FULL_ACCESS;

        dispatch(setCancellationCandidate(candidate));

        if (shouldFetchDiscount) {
            dispatch(
                fetchDiscountSubscriptions({
                    discount_type: DISCOUNT_TYPES.base,
                    external_id: candidate.external_id,
                })
            );
        }

        if (candidate.fee?.fee_enabled) {
            dispatch(openModal(ModalName.FEE_ALERT));
        } else if (isSecondSubscription || hasDiscount || hasOffer) {
            cancelByForce(void 0, candidate);
        } else {
            dispatch(openModal(ModalName.REASON_MODAL));
        }
    };

    const displaySkeleton = isLoading && !subscriptions?.length;

    const failedFirstIndex = subscriptions.findIndex(
        (subscription) => subscription.state === SUBSCRIPTION_PENDING_FAILED
    );

    return (
        <Box paddingTop={40} paddingBottom={40}>
            {displaySkeleton ? (
                <Skeleton height={128} />
            ) : (
                <div>
                    {Boolean(activeCount) && (
                        <Text type="h6" mb={20}>
                            {t('subscription.text.activeCount', { count: activeCount })}
                        </Text>
                    )}
                    {Boolean(canceledCount) && !Boolean(activeCount) && (
                        <Text type="h6" mb={20}>
                            {t('subscription.text.canceledCount', { count: canceledCount })}
                        </Text>
                    )}
                    {subscriptions?.map((subscription: ISubscription, index) => {
                        if (index === failedFirstIndex) {
                            return <PaymentFailedCard key={subscription.id} />;
                        }

                        if (subscription.state !== SUBSCRIPTION_PENDING_FAILED) {
                            return (
                                <div key={subscription.id} data-locator={`subscription${index}`}>
                                    <div key={index} data-locator={`subscription${index}`}>
                                        <SubscriptionCard
                                            subscription={subscription}
                                            onCancelSubscription={onCancelSubscription}
                                        />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            <SubscriptionHelpButton />
        </Box>
    );
};

export default SubscriptionsTab;
