import React from 'react';

import { Text } from 'components/library';

import * as S from './styled';

import { IFeePriceRow } from '../types';

const FeePriceRow = ({ period, price, priceHint }: IFeePriceRow) => {
    return (
        <S.PriceRow>
            <Text type="medium-text">{period}</Text>
            <Text type="medium-text" className="text-right">
                {price}
                {priceHint && <S.NotWrapBox>{priceHint}</S.NotWrapBox>}
            </Text>
        </S.PriceRow>
    );
};

export default FeePriceRow;
