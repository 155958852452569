import React from 'react';

import useSubscriptionCard from './hooks/useSubscriptionCard';

import SubscriptionRedemption from './components/SubscriptionRedemption';
import PendingSubscription from './components/PendingSubscription';
import PausedSubscription from './components/PausedSubscription';
import ExpiresSubscription from './components/ExpiresSubscription';
import ExpiredSubscription from './components/ExpiredSubscription';
import DefaultSubscription from './components/DefaultSubscription';

import { ISubscriptionCard } from './types';

const SubscriptionCard = ({ onCancelSubscription, subscription }: ISubscriptionCard) => {
    const {
        feeData,
        isExpiredSubscription,
        isExpiresSubscription,
        isSubscriptionRedemption,
        isPendingSubscription,
        isSubscriptionPaused,
        isFreeMonthActive,
        isMainSubscription,
        handleCancelSubscription,
        state,
        upcomingProduct,
        tabIcon,
        heading,
        subscriptionPeriod,
        subscriptionType,
        price,
        expirationDate,
    } = useSubscriptionCard({
        subscription,
        onCancelSubscription,
    });

    const commonProps = {
        feeData,
        tabIcon,
        heading,
        isMainSubscription,
        subscriptionPeriod,
        subscriptionType,
        price,
    };

    if (isExpiredSubscription) {
        return <ExpiredSubscription {...commonProps} expirationDate={expirationDate} />;
    }

    if (isExpiresSubscription) {
        const isDiscountOfferAvailable = !subscription.discounts.discount;

        return (
            <ExpiresSubscription
                {...commonProps}
                isDiscountOfferAvailable={isDiscountOfferAvailable}
                expirationDate={expirationDate}
            />
        );
    }

    if (isSubscriptionPaused) {
        return (
            <PausedSubscription
                {...commonProps}
                expirationDate={expirationDate}
                isMainSubscription={isMainSubscription}
                onCancelSubscription={handleCancelSubscription}
            />
        );
    }

    if (isSubscriptionRedemption) {
        return <SubscriptionRedemption {...commonProps} onCancelSubscription={handleCancelSubscription} />;
    }

    if (isPendingSubscription) {
        return (
            <PendingSubscription
                {...commonProps}
                expirationDate={expirationDate}
                state={state}
                upcomingProduct={upcomingProduct}
            />
        );
    }

    return (
        <DefaultSubscription
            {...commonProps}
            expirationDate={expirationDate}
            state={state}
            upcomingProduct={upcomingProduct}
            onCancelSubscription={handleCancelSubscription}
            withFreeMonth={isFreeMonthActive}
        />
    );
};

export default SubscriptionCard;
