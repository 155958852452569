import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { cancelFlow_offerResultClick, cancelFlow_offerResultScreenLoad } from 'services/analytics/trackers/cancelFlow';

import { useModals } from 'hooks';

import Modal from 'components/Modal/Modal';
import { Box, Button, Image, Text } from 'components/library';

import thumbUp from 'assets/img/icons/thumbUp.webp';
import success from 'assets/img/icons/success.png';
import handsUp from 'assets/img/icons/handsUp.webp';

import { SuccessModalContainerProps } from './types';

const icons = { success, thumbUp, handsUp };

const SuccessModalContainer = ({ offerType, title, subtitle, icon = 'thumbUp' }: SuccessModalContainerProps) => {
    const { t } = useTranslation();
    const { closeModal } = useModals();

    useEffect(() => {
        if (offerType) {
            cancelFlow_offerResultScreenLoad({
                result: 'success',
                offer_type: offerType,
            });
        }
    }, []);

    const handleClose = () => {
        closeModal();

        if (offerType) {
            cancelFlow_offerResultClick({
                action: 'close',
                result: 'success',
                offer_type: offerType,
            });
        }
    };

    return (
        <Modal isOpen withCloseButton onClose={handleClose}>
            <Box paddingTop={60} paddingBottom={20} paddingX={16}>
                <Image center maxWidth={50} src={icons[icon]} alt={icon} mb={12} />
                <Text type="h5" bold center mb={24} text={title} />
                <Text type="medium-text" center text={subtitle} />
                <Box paddingTop={25}>
                    <Button onClick={handleClose} text={t('basics.gotIt')} />
                </Box>
            </Box>
        </Modal>
    );
};

export default SuccessModalContainer;
