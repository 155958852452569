import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { OfferTypes, cancelFlow_offerResultScreenLoad } from 'services/analytics/trackers/cancelFlow';

import { useModals } from 'hooks';

import Modal from 'components/Modal/Modal';
import { Box, Button, Image, Text } from 'components/library';

import mailImage from './img/mail.svg';

const ReminderHasBeenSetModalContainer = () => {
    const { t } = useTranslation();
    const { closeModal } = useModals();

    useEffect(() => {
        cancelFlow_offerResultScreenLoad({
            result: 'success',
            offer_type: OfferTypes.Reminder_offer,
        });
    }, []);

    return (
        <Modal isOpen withCloseButton onClose={closeModal}>
            <Box paddingTop={24} paddingBottom={24} paddingX={16}>
                <Image center maxWidth={72} src={mailImage} alt="sandClock" mb={20} />
                <Text type="h5" bold center mb={24} text={t('subscription.reminderHasBeenSetModal.title')} />
                <Text type="medium-text" center mb={24} text={t('subscription.reminderHasBeenSetModal.subTitle')} />
                <Button
                    onClick={closeModal}
                    backgroundColor="primary-default"
                    text="basics.continue"
                    dataLocator="obContinue"
                />
            </Box>
        </Modal>
    );
};

export default ReminderHasBeenSetModalContainer;
