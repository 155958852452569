import createSagaMiddleware from 'redux-saga';

import { AQA_KEY, DEV_KEY } from 'constants/dev';

import config from '../config';

import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
        __getState: any;
    }
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const composeEnhancers = (config.ENV !== 'prod' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
    const persistor = persistStore(store);

    sagaMiddleware.run(rootSaga);

    return { store, persistor };
};

export const { store, persistor } = configureStore();

const urlParams = new URLSearchParams(window.location.search);
const refParams = urlParams.get(DEV_KEY);

if (refParams === AQA_KEY || localStorage.getItem(DEV_KEY)) {
    localStorage.setItem(DEV_KEY, AQA_KEY);

    window['__getState'] = () => store.getState();
}

export type Store = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
