import { Box, Text } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { openModal } from 'store/modals/actions';

import {
    OfferTypes,
    cancelFlow_cancelOfferClick,
    cancelFlow_cancelOfferScreenLoad,
} from 'services/analytics/trackers/cancelFlow';

import { useCancelSubscription, useModals, usePauseSubscription } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader';
import Modal from 'components/Modal';
import PauseOffer from './components/PauseOffer';

import * as S from './styled';

import { ModalName } from 'types/modals';

const PauseOfferModalContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { closeModal } = useModals();
    const { cancellationCandidate, discardCancellation, onCancelSubscription } = useCancelSubscription();
    const { onPauseSubscription } = usePauseSubscription();

    useEffect(() => {
        if (cancellationCandidate) {
            cancelFlow_cancelOfferScreenLoad({
                offer_type: OfferTypes.Pause_offer,
                subscription_id: cancellationCandidate.external_id,
            });
        }
    }, [cancellationCandidate]);

    const goBack = () => {
        dispatch(openModal(ModalName.REASON_MODAL));
    };

    const handlePause = () => {
        onPauseSubscription();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'claim_offer',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Pause_offer,
            });
        }
    };

    const handleClose = () => {
        closeModal();
        discardCancellation();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'close',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Pause_offer,
            });
        }
    };

    const handleCancel = () => {
        onCancelSubscription();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'cancel',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Pause_offer,
            });
        }
    };

    return (
        <Modal isOpen fullscreen customStyles={{ content: { overflow: 'scroll' } }}>
            <>
                <ModalHeader onBack={goBack} onClose={handleClose} />
                <S.Container dataLocator="pauseOfferContainer">
                    <Box paddingX={20}>
                        <Text type="h4" medium text={t('subscription.cancellation.pauseOffer.title')} mb={12} center />
                        <Text
                            type="large-text"
                            text={t('subscription.cancellation.pauseOffer.subtitle')}
                            mb={24}
                            center
                        />
                    </Box>
                    <PauseOffer
                        mb={24}
                        badgeBackgroundColor="discount"
                        badgeText="subscription.cancellation.pauseOffer.badgeText"
                        onPauseSubscription={handlePause}
                    />
                    <OutlinedButton
                        dataLocator="pauseOfferCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={handleCancel}
                    />
                </S.Container>
            </>
        </Modal>
    );
};

export default PauseOfferModalContainer;
