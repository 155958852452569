import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { openModal } from 'store/modals/actions';

import useReturnOffer from 'hooks/subscriptions/useReturnOffer';

import { Box, Button, Text } from 'components/library';

import * as S from './styled';

import { ModalName } from 'types/modals';
import { ISubscriptionCardCommonProps } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import StatusIcon from './StatusIcon';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';
import FeePriceRow from './FeePriceRow';

const ExpiresSubscription = ({
    feeData,
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    isMainSubscription,
    expirationDate,
    subscriptionType,
    isDiscountOfferAvailable,
}: ISubscriptionCardCommonProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { withFee, hasOfferFetched } = useReturnOffer();

    const showDiscountOfferModal = () => {
        dispatch(openModal(ModalName.RETURN_OFFER));
    };

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.TextBox>
                <Text
                    color="text-secondary"
                    text={t('subscription.cancellation.expiresSubscription.bannerText', { expirationDate })}
                />
            </S.TextBox>
            <S.Status>
                <StatusIcon isCancelled isActive />
                <Text type="caption" color="text-secondary" text={t('subscription.status.canceled')} />
                <Text type="caption" color="text-secondary" text=":" />
            </S.Status>
            <Box paddingBottom={isDiscountOfferAvailable ? 20 : 0}>
                {feeData ? (
                    <FeePriceRow
                        period={t('subscription.feeYear')}
                        price={feeData.fee_total_price}
                        priceHint={t('subscription.feePrice', { price })}
                    />
                ) : (
                    <PriceRow period={subscriptionPeriod} subscriptionType={subscriptionType} price={price} />
                )}
            </Box>
            {isDiscountOfferAvailable && hasOfferFetched && isMainSubscription && !withFee && (
                <Button text="Show return offer" onClick={showDiscountOfferModal} />
            )}
        </SubscriptionCardWrapper>
    );
};

export default ExpiresSubscription;
