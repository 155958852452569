import { Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { usePauseSubscription } from 'hooks';

import { Button } from 'components/library';
import { Pause } from 'components/Icon';

import * as S from './styled';

import { IPausedSubscription } from '../types';

import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';
import CancelButton from './CancelButton';

const PausedSubscription = ({
    tabIcon,
    heading,
    subscriptionPeriod,
    price,
    onCancelSubscription,
    subscriptionType,
    isMainSubscription,
}: IPausedSubscription) => {
    const { t } = useTranslation();

    const { pauseToDate, onResumeSubscription } = usePauseSubscription();

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.GeneralInfo mb={0}>
                <S.TextBox>
                    <Text
                        color="text-secondary"
                        text={t('subscription.cancellation.pausedSubscription.bannerText', { pauseToDate })}
                    />
                </S.TextBox>
                <S.Status>
                    <Pause />
                    <Text type="caption" color="text-secondary" text={t(`subscription.status.paused`)} />
                    <Text type="caption" color="text-secondary" text=":" />
                </S.Status>
                <PriceRow price={price} period={subscriptionPeriod} subscriptionType={subscriptionType} />
            </S.GeneralInfo>
            {isMainSubscription && (
                <Box paddingTop={20}>
                    <Button onClick={onResumeSubscription} text={t('subscription.text.resumeSubscription')} />
                </Box>
            )}
            <Box paddingTop={10}>
                <CancelButton onCancel={onCancelSubscription} />
            </Box>
        </SubscriptionCardWrapper>
    );
};

export default PausedSubscription;
