import React from 'react';

import { selectCancellationCandidate } from 'store/subscriptions/selectors';
import { openModal } from 'store/modals/actions';

import { reasonToModal, reasonToModalInary } from 'modals/CancellationReasonModal/constants';

import { useCancelSubscription, useDispatch, useModals, useSelector } from 'hooks';

import CancellationReasonModal from 'modals/CancellationReasonModal';

import { PaymentProviders } from 'types/subscription';

const CancellationReasonModalContainer = () => {
    const dispatch = useDispatch();
    const { closeModal } = useModals();

    const { discardCancellation, onCancelSubscription } = useCancelSubscription();
    const cancellationCandidate = useSelector(selectCancellationCandidate);

    const onSubmit = (reason: string) => {
        if (cancellationCandidate?.payment_provider === PaymentProviders.Inary) {
            dispatch(openModal(reasonToModalInary[reason]));
        } else {
            dispatch(openModal(reasonToModal[reason]));
        }
    };

    const handleClose = () => {
        closeModal();
        discardCancellation();
    };

    return <CancellationReasonModal onSubmit={onSubmit} onClose={handleClose} onCancel={onCancelSubscription} />;
};

export default CancellationReasonModalContainer;
