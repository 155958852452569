import { Box, Text } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import { openModal } from 'store/modals/actions';

import {
    OfferTypes,
    cancelFlow_otherReasonClick,
    cancelFlow_otherReasonScreenLoad,
} from 'services/analytics/trackers/cancelFlow';

import useFeedbackSubmission from 'hooks/subscriptions/useFeedbackSubmission';
import { useCancelSubscription, useModals } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader/ModalHeader';
import Modal from 'components/Modal';
import { Button } from 'components/library';

import * as S from './styled';

import { FeedbackFormValues } from 'types/subscription';
import { ModalName } from 'types/modals';

import validationSchema from './validation-scheme';

const FeedbackFormContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { closeModal } = useModals();
    const { isSubmitting, submitFeedback } = useFeedbackSubmission();
    const { cancellationCandidate, onCancelSubscription, discardCancellation } = useCancelSubscription();

    useEffect(() => {
        if (cancellationCandidate) {
            cancelFlow_otherReasonScreenLoad({
                offer_type: OfferTypes.Discount_offer,
                subscription_id: cancellationCandidate.external_id,
            });
        }
    }, [cancellationCandidate]);

    const submitSurvey = async (data: FeedbackFormValues) => {
        if (cancellationCandidate) {
            cancelFlow_otherReasonClick({
                action: 'continue',
                comment: data.comment,
                offer_type: OfferTypes.Discount_offer,
                subscription_id: cancellationCandidate.external_id,
            });
        }

        await submitFeedback(data);
    };

    const { values, handleChange, handleSubmit, errors, handleBlur, touched, isValid } = useFormik<FeedbackFormValues>({
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: submitSurvey,
        initialValues: {
            comment: '',
            // Currently this form works only for the "other" reason of survey submission
            reason: 'other',
        },
    });

    const isError = Boolean(errors.comment && touched.comment);

    const goBack = () => {
        dispatch(openModal(ModalName.REASON_MODAL));
    };

    const handleCancel = () => {
        if (cancellationCandidate) {
            cancelFlow_otherReasonClick({
                action: 'cancel',
                comment: values.comment,
                offer_type: OfferTypes.Discount_offer,
                subscription_id: cancellationCandidate.external_id,
            });
        }

        onCancelSubscription();
    };

    const handleClose = () => {
        closeModal();
        discardCancellation();

        if (cancellationCandidate) {
            cancelFlow_otherReasonClick({
                action: 'close',
                comment: values.comment,
                subscription_id: cancellationCandidate.external_id,
            });
        }
    };

    return (
        <Modal isOpen fullscreen>
            <>
                <ModalHeader onBack={goBack} onClose={handleClose} />
                <S.Container>
                    <Text type="h4" medium center mb={12} text={t('subscription.cancellation.feedbackForm.title')} />
                    <S.Form onSubmit={handleSubmit}>
                        <Box mb={30}>
                            <S.Textarea
                                rows={5}
                                name="comment"
                                hasError={false}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.comment}
                                placeholder={t('subscription.cancellation.feedbackForm.input.placeholder')}
                            />
                            <Box paddingX={12} paddingTop={6}>
                                <Text
                                    bold
                                    type="caption"
                                    color={isError ? 'critical-default' : 'text-secondary'}
                                    text={t('subscription.cancellation.feedbackForm.input.hint')}
                                />
                            </Box>
                        </Box>

                        <Button
                            disabled={!isValid}
                            isLoading={isSubmitting}
                            text={t('basics.continue')}
                            onClick={handleSubmit}
                        />
                    </S.Form>
                    <OutlinedButton
                        dataLocator="freeMonthCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={handleCancel}
                    />
                </S.Container>
            </>
        </Modal>
    );
};

export default FeedbackFormContainer;
