import { Box, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import {
    OfferTypes,
    cancelFlow_cancelOfferClick,
    cancelFlow_cancelOfferScreenLoad,
} from 'services/analytics/trackers/cancelFlow';

import useReturnOffer from 'hooks/subscriptions/useReturnOffer';
import { useModals } from 'hooks';

import ModalHeader from 'components/ModalHeader/ModalHeader';
import Modal from 'components/Modal';
import DiscountOffer from 'components/DiscountOffer';

import * as S from './styled';

const ReturnOfferContainer = () => {
    const { t } = useTranslation();
    const { closeModal } = useModals();

    const { resetCandidate, resetCandidatePriceData, discountPriceData, onResetSubscription } = useReturnOffer();

    const handleReset = () => {
        onResetSubscription();

        if (resetCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'claim_offer',
                subscription_id: resetCandidate.external_id,
                offer_type: OfferTypes.Return_offer,
            });
        }
    };

    const handleClose = () => {
        closeModal();

        if (resetCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'close',
                subscription_id: resetCandidate.external_id,
                offer_type: OfferTypes.Return_offer,
            });
        }
    };

    useEffect(() => {
        if (resetCandidate) {
            cancelFlow_cancelOfferScreenLoad({
                offer_type: OfferTypes.Return_offer,
                subscription_id: resetCandidate.external_id,
            });
        }
    }, [resetCandidate]);

    return (
        <Modal isOpen fullscreen>
            <>
                <ModalHeader onClose={handleClose} />
                <S.ModalContainer data-locator="discountOfferContainer">
                    <Box paddingX={25}>
                        <Text
                            text={t('subscription.cancellation.specialOffer.withDynamicPercent.title', { percent: 80 })}
                            type="h4"
                            medium
                            center
                            mb={12}
                        />
                        <Text
                            text={t('subscription.cancellation.specialOffer.withDynamicPercent.subtitle')}
                            type="large-text"
                            center
                            mb={24}
                        />
                    </Box>
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="text-secondary"
                        badgeText="subscription.cancellation.specialOffer.firstBadge"
                        price={resetCandidatePriceData!.price}
                        period={resetCandidatePriceData!.period}
                        currencySign={resetCandidatePriceData!.currency}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="critical-default"
                        badgeText="subscription.cancellation.specialOffer.withDynamicPercent.secondBadge"
                        badgePercent={50}
                        isNewOffer
                        oldPrice={resetCandidatePriceData!.price}
                        price={discountPriceData!.price}
                        period={discountPriceData!.period}
                        currencySign={discountPriceData!.currency}
                        onSubmitDiscount={handleReset}
                    />
                </S.ModalContainer>
            </>
        </Modal>
    );
};

export default ReturnOfferContainer;
