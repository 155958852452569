import * as actionTypes from './actionTypes';

import { IDiscountSubscription, ISubscription } from 'types/subscription';
import {
    ICancelSubscription,
    IFetchDiscountSubscription,
    IFetchSubscriptions,
    IPauseSubscription,
    IResetSubscription,
    IResumeSubscription,
    ISendFeedback,
} from './types';

export const setCancellationCandidate = (payload: ISubscription | null) => {
    return {
        type: actionTypes.SET_CANCELLATION_CANDIDATE,
        payload,
    } as const;
};

export const fetchSubscriptions = (payload: IFetchSubscriptions = {}) => {
    return {
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const setSubscriptions = (payload: Array<ISubscription>) => {
    return {
        type: actionTypes.SET_USER_SUBSCRIPTIONS,
        payload,
    } as const;
};

export const pauseSubscription = (payload: IPauseSubscription) => {
    return {
        type: actionTypes.PAUSE_SUBSCRIPTION,
        payload,
    } as const;
};

export const resumeSubscription = (payload: IResumeSubscription) => {
    return {
        type: actionTypes.RESUME_SUBSCRIPTION,
        payload,
    } as const;
};

export const cancelSubscription = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
};

export const discardSubscriptionCancellation = () => {
    return {
        type: actionTypes.DISCARD_SUBSCRIPTION_CANCELLATION,
    } as const;
};

export const setSubscriptionsFetchingStatus = (payload: boolean) => {
    return {
        type: actionTypes.SET_FETCHING_STATUS,
        payload,
    } as const;
};

export const setSubscriptionCancelReminder = (payload: ICancelSubscription) => {
    return {
        type: actionTypes.SET_CANCEL_REMINDER,
        payload,
    } as const;
};

export const fetchDiscountSubscriptions = (payload: IFetchDiscountSubscription) => {
    return {
        type: actionTypes.FETCH_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const setIsDiscountLoading = (payload: boolean) => {
    return {
        type: actionTypes.SET_IS_DISCOUNT_LOADING,
        payload,
    } as const;
};

export const setDiscountSubscription = (payload: IDiscountSubscription | null) => {
    return {
        type: actionTypes.SET_DISCOUNT_SUBSCRIPTION,
        payload,
    } as const;
};

export const updateSubscription = (payload: any) => {
    return {
        type: actionTypes.UPDATE_SUBSCRIPTION,
        payload,
    } as const;
};

export const resetSubscription = (payload: IResetSubscription) => {
    return {
        type: actionTypes.RESET_SUBSCRIPTION,
        payload,
    } as const;
};

export const sendFeedback = (payload: ISendFeedback) => {
    return {
        type: actionTypes.SEND_SUBSCRIPTION_FEEDBACK,
        payload,
    } as const;
};
