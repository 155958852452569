import { ISubscriptionCancelResponse } from 'api/types/response';

import { IFetchDiscountSubscription } from 'store/subscriptions/types';

import {
    ICancelSubscriptionRequest,
    IDiscountSubscription,
    IPauseType,
    IResetSubscription,
    ISubscription,
    IUpdateSubscription,
    PaymentProviders,
} from 'types/subscription';

import Base from './Base';

class SubscriptionsApi extends Base {
    getSubscriptions = async <Response = ISubscription>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>('user/subscription');
    };

    setReminder = async (payload: { external_id: string; provider: PaymentProviders }): Promise<string> => {
        return await this.apiClient.private.post('user/subscription/reminder', payload);
    };

    pauseSubscription = async <Response = string>(payload: {
        id: string;
        provider: PaymentProviders;
        pause_type: IPauseType;
    }): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post('subscriptions/pause', payload);
    };

    resumeSubscription = async <Response = string>(payload: {
        id: string;
        provider: PaymentProviders;
    }): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post('subscriptions/resume', payload);
    };

    resetSubscription = async <Response = string>(
        payload: IResetSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post('solidgate/subscription/reset', payload);
    };

    unsubscribe = async <Data = ICancelSubscriptionRequest, Response = ISubscriptionCancelResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<Data, Response>('subscriptions/cancel', payload);
    };

    getDiscountSubscriptions = async <Response = IDiscountSubscription>(
        payload: IFetchDiscountSubscription
    ): Promise<Response> => {
        return await this.apiClient.private.post<typeof payload, Response>('solidgate/subscription/discount', payload);
    };

    updateSubscription = async <Response = IDiscountSubscription>(
        payload: IUpdateSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<typeof payload, Response>('solidgate/subscription/switch', payload);
    };

    sendFeedback = async (data: any) => {
        return await this.apiClient.private.post<any, any>(`feedback`, data);
    };
}

export default SubscriptionsApi;
