import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import { pauseSubscription, resumeSubscription } from 'store/subscriptions/actions';

import { PRODUCT_CODES } from 'constants/payments';

import {
    OfferTypes,
    cancelFlow_offerResultScreenLoad,
    cancelFlow_subscriptionClick,
} from 'services/analytics/trackers/cancelFlow';

import { useModals, useSelector, useDispatch } from 'hooks/index';

import { getFormattedDateByCountry } from 'helpers/date';

import { ISubscription } from 'types/subscription';
import { UserStore } from 'types/store/userStore';
import { ModalName } from 'types/modals';

const PAUSE_TYPE = 'pause';
const FREE_MONTH_TYPE = 'free_month';

const getMainSubscription = (subscriptions: ISubscription[]) => {
    return subscriptions.find((s) => s.product_code === PRODUCT_CODES.APP_FULL_ACCESS);
};

const usePauseSubscription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal } = useModals();
    const { subscriptions } = useSelector(selectSubscriptions);

    const mainSubscription = getMainSubscription(subscriptions);
    const currentUser = useSelector(selectCurrentUser) as UserStore;
    const pauseToDate = getFormattedDateByCountry(mainSubscription?.pause.pause_to || '', currentUser.country!);

    const onPauseSubscription = useCallback(() => {
        openModal(ModalName.WAITING_MODAL, { title: 'subscription.modals.updating' });

        if (mainSubscription) {
            dispatch(
                pauseSubscription({
                    id: mainSubscription.external_id,
                    provider: mainSubscription.payment_provider,
                    pause_type: PAUSE_TYPE,
                    onSuccess: (subscriptions: ISubscription[]) => {
                        const sub = getMainSubscription(subscriptions);
                        const pauseToDate = getFormattedDateByCountry(sub?.pause.pause_to || '', currentUser.country!);

                        openModal(ModalName.SUCCESS_MODAL, {
                            offerType: OfferTypes.Pause_offer,
                            title: t('subscription.cancelFlow.pauseOffer.pause.successModal.title'),
                            subtitle: t('subscription.cancelFlow.pauseOffer.pause.successModal.subtitle', {
                                pauseToDate,
                            }),
                        });
                    },
                    onError: () => {
                        openModal(ModalName.WARNING_MODAL, {
                            offerType: OfferTypes.Pause_offer,
                            onMainBtnClick: onPauseSubscription,
                            title: t('subscription.cancelFlow.errorModal.title'),
                            analyticScreenLoadHandler() {
                                cancelFlow_offerResultScreenLoad({
                                    result: 'fail',
                                    offer_type: OfferTypes.Pause_offer,
                                });
                            },
                        });
                    },
                })
            );
        }
    }, [mainSubscription, currentUser]);

    const onGetFreeMonthPause = useCallback(() => {
        openModal(ModalName.WAITING_MODAL, { title: 'subscription.modals.updating' });

        if (mainSubscription) {
            dispatch(
                pauseSubscription({
                    id: mainSubscription.external_id,
                    provider: mainSubscription.payment_provider,
                    pause_type: FREE_MONTH_TYPE,
                    onSuccess: (subscriptions: ISubscription[]) => {
                        const sub = getMainSubscription(subscriptions);
                        const renewalDate = getFormattedDateByCountry(sub?.expired_date || '', currentUser.country!);

                        openModal(ModalName.SUCCESS_MODAL, {
                            icon: 'success',
                            offerType: OfferTypes.Free_month,
                            title: t('subscription.cancelFlow.monthFreeOffer.successModal.title'),
                            subtitle: t('subscription.cancelFlow.pauseOffer.successModal.subtitle', { renewalDate }),
                        });
                    },
                    onError: () => {
                        openModal(ModalName.WARNING_MODAL, {
                            offerType: OfferTypes.Free_month,
                            onMainBtnClick: onPauseSubscription,
                            title: t('subscription.cancelFlow.errorModal.title'),
                            analyticScreenLoadHandler() {
                                cancelFlow_offerResultScreenLoad({
                                    result: 'fail',
                                    offer_type: OfferTypes.Free_month,
                                });
                            },
                        });
                    },
                })
            );
        }
    }, [mainSubscription, currentUser]);

    const onResumeSubscription = useCallback(() => {
        openModal(ModalName.WAITING_MODAL, { title: 'subscription.modals.updating' });

        if (mainSubscription) {
            cancelFlow_subscriptionClick({
                status: mainSubscription.state,
                action: 'resume',
                reminder: !!mainSubscription.reminder,
                free_month: !!mainSubscription.discounts.free_month,
                subscription_id: mainSubscription.external_id,
            });

            dispatch(
                resumeSubscription({
                    id: mainSubscription.external_id,
                    provider: mainSubscription.payment_provider,
                    onSuccess: (subscriptions: ISubscription[]) => {
                        const sub = getMainSubscription(subscriptions);
                        const renewalDate = getFormattedDateByCountry(sub?.expired_date || '', currentUser.country!);

                        openModal(ModalName.SUCCESS_MODAL, {
                            title: t('subscription.cancelFlow.pauseOffer.resume.successModal.title'),
                            subtitle: t('subscription.cancelFlow.pauseOffer.successModal.subtitle', { renewalDate }),
                        });
                    },
                    onError: () => {
                        openModal(ModalName.WARNING_MODAL, {
                            offerType: OfferTypes.Pause_offer,
                            onMainBtnClick: onPauseSubscription,
                            title: t('subscription.cancelFlow.errorModal.title'),
                            analyticScreenLoadHandler() {
                                cancelFlow_offerResultScreenLoad({
                                    result: 'fail',
                                    offer_type: OfferTypes.Pause_offer,
                                });
                            },
                        });
                    },
                })
            );
        }
    }, [mainSubscription, currentUser]);

    return { pauseToDate, onPauseSubscription, onResumeSubscription, onGetFreeMonthPause };
};

export default usePauseSubscription;
