import { useEffect } from 'react';

import { fetchSubscriptions } from 'store/subscriptions/actions';

import { useDispatch, useSelector } from 'hooks';

const useSubscriptions = () => {
    const dispatch = useDispatch();
    const { subscriptions, isLoading, isDiscountLoading } = useSelector((state) => state.subscriptions);

    useEffect(() => {
        dispatch(fetchSubscriptions());
    }, []);

    return {
        isLoading,
        subscriptions,
        isDiscountLoading,
    };
};

export default useSubscriptions;
