import { useTranslation } from 'react-i18next';
import React from 'react';

import { SUBSCRIPTION_ACTIVE } from 'constants/payments';

import { isEmpty } from 'helpers/dataStructuresUtils';

import { Box, Text } from 'components/library';

import * as S from './styled';

import { IDefaultSubscription } from '../types';

import UpcomingProduct from './UpcomingProduct';
import SubscriptionCardWrapper from './SubscriptionCardWrapper';
import StatusIcon from './StatusIcon';
import SubscriptionCardHeader from './SabscriptionCardHeader';
import PriceRow from './PriceRow';
import FeePriceRow from './FeePriceRow';
import CancelButton from './CancelButton';

const DefaultSubscription = ({
    feeData,
    tabIcon,
    heading,
    state,
    withFreeMonth,
    subscriptionPeriod,
    price,
    expirationDate,
    onCancelSubscription,
    upcomingProduct,
    subscriptionType,
}: IDefaultSubscription) => {
    const { t } = useTranslation();

    const internalState = withFreeMonth ? SUBSCRIPTION_ACTIVE : state;

    return (
        <SubscriptionCardWrapper>
            <SubscriptionCardHeader icon={tabIcon} heading={heading} />
            <S.GeneralInfo mb={0}>
                <S.Status>
                    <StatusIcon isActive={internalState === SUBSCRIPTION_ACTIVE} />
                    <Text type="caption" color="text-secondary" text={t(`subscription.status.${internalState}`)} />
                    <Text type="caption" color="text-secondary" text=":" />
                </S.Status>
                {feeData ? (
                    <FeePriceRow
                        period={t('subscription.feeYear')}
                        price={feeData.fee_total_price}
                        priceHint={t('subscription.feePrice', { price })}
                    />
                ) : (
                    <PriceRow
                        price={price}
                        period={subscriptionPeriod}
                        subscriptionType={subscriptionType}
                        withFreeMonth={withFreeMonth}
                    />
                )}
                <S.DateInfo>
                    {feeData ? (
                        <Text
                            type="caption"
                            color="text-secondary-subdued"
                            text={t('subscription.text.nextInstallment')}
                        />
                    ) : (
                        <Text type="caption" color="text-secondary-subdued" text={t('subscription.text.expires')} />
                    )}
                    <Text type="caption" color="text-secondary-subdued" text={expirationDate} />
                </S.DateInfo>
            </S.GeneralInfo>
            {upcomingProduct && !isEmpty(upcomingProduct) && !feeData && (
                <UpcomingProduct upcomingProduct={upcomingProduct} />
            )}
            <Box paddingTop={20}>
                <CancelButton onCancel={onCancelSubscription} />
            </Box>
        </SubscriptionCardWrapper>
    );
};

export default DefaultSubscription;
