import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { fetchSubscriptions, updateSubscription } from 'store/subscriptions/actions';

import { OfferTypes, cancelFlow_offerResultScreenLoad } from 'services/analytics/trackers/cancelFlow';

import { useModals, useSelector } from 'hooks';

import { getPriceFromCents } from 'helpers/prices';

import { ModalName } from 'types/modals';

type PriceData = {
    currency: string;
    period: number;
    price: string;
};

type UseSpecialOfferModal = {
    isDiscountLoading: boolean;
    candidatePriceData: PriceData | null;
    discountPriceData: PriceData | null;
    onUpdateSubscription: () => void;
};

const useSpecialOfferModal = (externalId: string): UseSpecialOfferModal => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal } = useModals();

    const { subscriptions, discountSubscription, isDiscountLoading } = useSelector(
        ({ subscriptions }) => subscriptions
    );

    const cancelCandidate = subscriptions.find(({ external_id }) => external_id === externalId)!;

    const candidatePriceData = {
        currency: cancelCandidate.product.currency,
        period: cancelCandidate.subscription_period,
        price: getPriceFromCents(cancelCandidate.product.amount) + '',
    };

    const discountPriceData = {
        currency: discountSubscription!.currency,
        period: discountSubscription!.subscription_period,
        price: getPriceFromCents(discountSubscription!.amount) + '',
    };

    const onUpdateSubscription = useCallback(() => {
        if (cancelCandidate && discountSubscription) {
            openModal(ModalName.WAITING_MODAL, { title: 'subscription.modals.updating' });

            dispatch(
                updateSubscription({
                    updatedSubscription: {
                        external_id: cancelCandidate.external_id,
                        product: discountSubscription,
                    },
                    onSuccess: () => {
                        dispatch(fetchSubscriptions());

                        openModal(ModalName.SUCCESS_MODAL, {
                            icon: 'handsUp',
                            offerType: OfferTypes.Discount_offer,
                            title: t('subscription.cancelFlow.specialOffer.discount.successModal.title'),
                            subtitle: t('subscription.cancelFlow.specialOffer.discount.successModal.subtitle', {
                                price: discountPriceData.price,
                                period: discountPriceData.period,
                                currencySign: discountPriceData.currency,
                            }),
                        });
                    },
                    onError: () => {
                        openModal(ModalName.WARNING_MODAL, {
                            offerType: OfferTypes.Return_offer,
                            onMainBtnClick: onUpdateSubscription,
                            title: t('subscription.cancelFlow.errorModal.title'),
                            analyticScreenLoadHandler() {
                                cancelFlow_offerResultScreenLoad({
                                    result: 'fail',
                                    offer_type: OfferTypes.Discount_offer,
                                });
                            },
                        });
                    },
                })
            );
        }
    }, [cancelCandidate, discountSubscription]);

    return { candidatePriceData, discountPriceData, isDiscountLoading, onUpdateSubscription };
};

export default useSpecialOfferModal;
