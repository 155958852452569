import { ISubscription } from 'types/subscription';

import normalizeDateByLocale from './normalizeDateByLocale';

interface IUpomingProductProps {
    subscription: ISubscription;
    country?: null | string;
}

const getUpcomingProduct = ({ subscription, country }: IUpomingProductProps) => {
    const { product, is_trial, expired_date, cancelled_at, period, internal_cancel_at, upcoming_product } =
        subscription;

    const isCancelledSubscription = cancelled_at !== null || internal_cancel_at !== null;
    const expirationDate = normalizeDateByLocale(expired_date, country);

    if (is_trial && !isCancelledSubscription) {
        return {
            currency: product.currency,
            started_from: expirationDate,
            amount: upcoming_product?.amount ?? product?.amount,
            subscription_period: upcoming_product?.subscription_period ?? period,
        };
    }

    return null;
};

export default getUpcomingProduct;
