import WarningModalContainer from 'containers/modals/WarningModalContainer';
import WaitingModalContainer from 'containers/modals/WaitingModalContainer';
import SuccessModalContainer from 'containers/modals/SuccessModalContainer/SuccessModalContainer';
import SpecialOfferModalContainer from 'containers/modals/SpecialOfferModalContainer/SpecialOfferModalContainer';
import ReturnOfferContainer from 'containers/modals/ReturnOfferContainer/ReturnOfferContainer';
import ReminderReasonModalContainer from 'containers/modals/ReminderReasonModalContainer';
import ReminderHasBeenSetModalContainer from 'containers/modals/ReminderHasBeenSetModalContainer';
import PauseOfferModalContainer from 'containers/modals/PauseOfferModalContainer/PauseOfferModalContainer';
import FreezeReasonModalContainer from 'containers/modals/FreezeReasonModalContainer';
import FreezePeriodModalContainer from 'containers/modals/FreezePeriodModalContainer';
import FreezeNotAvailableModalContainer from 'containers/modals/FreezeNotAvailableModalСontainer';
import FreezeAlertModalContainer from 'containers/modals/FreezeAlertModalContainer';
import FreeMonthModalContainer from 'containers/modals/FreeMonthModalContainer/FreeMonthModalContainer';
import FeedbackFormContainer from 'containers/modals/FeedbackFormContainer/FeedbackFormContainer';
import ErrorModalContainer from 'containers/modals/ErrorModalContainer';
import DeleteAccountModalContainer from 'containers/modals/DeleteAccountModalContainer/DeleteAccountModalContainer';
import CancellationReasonModalContainer from 'containers/modals/CancellationReasonModalContainer';
import CancelationFeeModalContainer from 'containers/modals/CancelationFeeModalContainer/CancelationFeeModalContainer';
import AlertModalContainer from 'containers/modals/AlertModalСontainer';
import AccountWasDeletedContainer from 'containers/modals/AccountWasDeletedSuccessContainer';
import AccountDeleteErrorContainer from 'containers/modals/AccountDeleteErrorContainer/AccountDeleteErrorContainer';

import { ModalName, ModalsType } from 'types/modals';

export const MODAL_LIST: ModalsType = {
    [ModalName.FEE_ALERT]: CancelationFeeModalContainer,
    [ModalName.ALERT_MODAL]: AlertModalContainer,
    [ModalName.REASON_MODAL]: CancellationReasonModalContainer,
    [ModalName.REMINDER_MODAL]: ReminderReasonModalContainer,
    [ModalName.PAUSE_MODAL]: PauseOfferModalContainer,
    [ModalName.FREE_MONTH_MODAL]: FreeMonthModalContainer,
    [ModalName.FEEDBACK_FORM]: FeedbackFormContainer,
    [ModalName.SPECIAL_OFFER]: SpecialOfferModalContainer,
    [ModalName.RETURN_OFFER]: ReturnOfferContainer,
    [ModalName.WARNING_MODAL]: WarningModalContainer,
    [ModalName.SUCCESS_MODAL]: SuccessModalContainer,
    [ModalName.WAITING_MODAL]: WaitingModalContainer,
    [ModalName.FREEZE_ALERT_MODAL]: FreezeAlertModalContainer,
    [ModalName.FREEZE_REASON_MODAL]: FreezeReasonModalContainer,
    [ModalName.FREEZE_PERIOD_MODAL]: FreezePeriodModalContainer,
    [ModalName.FREEZE_NOT_AVAILABLE_MODAL]: FreezeNotAvailableModalContainer,
    [ModalName.REMINDER_HAS_BEEN_SET_MODAL]: ReminderHasBeenSetModalContainer,
    [ModalName.ERROR_MODAL]: ErrorModalContainer,
    [ModalName.DELETE_ACCOUNT]: DeleteAccountModalContainer,
    [ModalName.DELETE_ACCOUNT_SUCCESS]: AccountWasDeletedContainer,
    [ModalName.DELETE_ACCOUNT_ERROR]: AccountDeleteErrorContainer,
};
