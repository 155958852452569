import { Image, Text } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { openModal } from 'store/modals/actions';

import {
    OfferTypes,
    cancelFlow_cancelOfferClick,
    cancelFlow_cancelOfferScreenLoad,
} from 'services/analytics/trackers/cancelFlow';

import { usePauseSubscription, useSelector, useCancelSubscription, useModals } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader/ModalHeader';
import Modal from 'components/Modal';
import { Button } from 'components/library';

import * as S from './styled';

import presentImg from './img/present.webp';

import { ModalName } from 'types/modals';

const FreeMonthModalContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { closeModal } = useModals();

    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { discardCancellation, onCancelSubscription } = useCancelSubscription();
    const { onGetFreeMonthPause } = usePauseSubscription();

    useEffect(() => {
        if (cancellationCandidate) {
            cancelFlow_cancelOfferScreenLoad({
                offer_type: OfferTypes.Free_month,
                subscription_id: cancellationCandidate.external_id,
            });
        }
    }, [cancellationCandidate]);

    if (!cancellationCandidate) {
        return null;
    }

    const handleOfferApply = () => {
        onGetFreeMonthPause();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'claim_offer',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Free_month,
            });
        }
    };

    const handleCancel = () => {
        onCancelSubscription();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'cancel',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Free_month,
            });
        }
    };

    const goBack = () => {
        dispatch(openModal(ModalName.REASON_MODAL));
    };

    const handleClose = () => {
        closeModal();
        discardCancellation();

        if (cancellationCandidate) {
            cancelFlow_cancelOfferClick({
                action: 'close',
                subscription_id: cancellationCandidate.external_id,
                offer_type: OfferTypes.Free_month,
            });
        }
    };

    return (
        <Modal isOpen fullscreen>
            <>
                <ModalHeader onBack={goBack} onClose={handleClose} />
                <S.Container dataLocator="freeMonthOfferContainer">
                    <Image alt="present" src={presentImg} maxWidth={200} center />
                    <S.ContentBlock>
                        <Text
                            type="h4"
                            medium
                            text={t('subscription.cancellation.monthFreeOffer.title')}
                            mb={20}
                            center
                        />
                        <Text
                            type="large-text"
                            text={t('subscription.cancellation.monthFreeOffer.text')}
                            mb={8}
                            center
                        />
                        <Text
                            type="h6"
                            medium
                            text={t('subscription.cancellation.monthFreeOffer.offer')}
                            mb={16}
                            center
                        />
                    </S.ContentBlock>
                    <Button
                        text={t('subscription.cancellation.monthFreeOffer.getFreeMonthBtnText')}
                        onClick={handleOfferApply}
                        dataLocator="freeMonthGetFree1MonthButton"
                    />
                    <OutlinedButton
                        dataLocator="freeMonthCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={handleCancel}
                    />
                </S.Container>
            </>
        </Modal>
    );
};

export default FreeMonthModalContainer;
