import { ModalName } from 'types/modals';

export const reasons = [
    {
        title: 'subscription.cancellationReason.forgetCancel',
        shortKey: 'forget_to_cancel_later',
    },
    {
        title: 'subscription.cancellationReason.tooExpensive',
        shortKey: 'too_expensive',
    },
    {
        title: 'subscription.cancellationReason.noTime',
        shortKey: 'no_time',
    },
    {
        title: 'subscription.cancellationReason.workoutExpectation',
        shortKey: 'workout_not_meets_expectations',
    },
    {
        title: 'subscription.cancellationReason.appExpectation',
        shortKey: 'app_not_meets_expectations',
    },
    {
        title: 'subscription.cancellationReason.motivation',
        shortKey: 'no_motivation',
    },
    {
        title: 'subscription.cancellationReason.healthIssue',
        shortKey: 'health_issue',
    },
    {
        title: 'subscription.cancellationReason.other',
        shortKey: 'other',
    },
];

export const reasonToModal: Record<string, ModalName> = {
    too_expensive: ModalName.SPECIAL_OFFER,
    forget_to_cancel_later: ModalName.REMINDER_MODAL,
    no_motivation: ModalName.PAUSE_MODAL,
    health_issue: ModalName.PAUSE_MODAL,
    no_time: ModalName.PAUSE_MODAL,
    workout_not_meets_expectations: ModalName.FREE_MONTH_MODAL,
    app_not_meets_expectations: ModalName.FREE_MONTH_MODAL,
    other: ModalName.FEEDBACK_FORM,
};

export const reasonToModalInary: Record<string, ModalName> = {
    ...reasonToModal,
    too_expensive: ModalName.FREE_MONTH_MODAL,
};

export const freezeReasons = [
    {
        title: 'subscription.reasonFreeze.option1',
        shortKey: 'fell_ill',
    },
    {
        title: 'subscription.reasonFreeze.option2',
        shortKey: 'cant_follow_now',
    },
    {
        title: 'subscription.reasonFreeze.option3',
        shortKey: 'no_use',
    },
    {
        title: 'subscription.reasonFreeze.option4',
        shortKey: 'start_later',
    },
    {
        title: 'subscription.reasonFreeze.option5',
        shortKey: 'other',
    },
];
