import { Box, Text } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import { openModal } from 'store/modals/actions';

import {
    OfferTypes,
    cancelFlow_cancelOfferClick,
    cancelFlow_cancelOfferScreenLoad,
} from 'services/analytics/trackers/cancelFlow';

import useSpecialOfferModal from 'hooks/subscriptions/useSpecialOfferModal';
import { useCancelSubscription, useModals } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import ModalHeader from 'components/ModalHeader/ModalHeader';
import Modal from 'components/Modal';
import DiscountOffer from 'components/DiscountOffer';

import * as S from './styled';

import { ModalName } from 'types/modals';

const SpecialOfferModalContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { closeModal } = useModals();
    const { cancellationCandidate, onCancelSubscription, discardCancellation } = useCancelSubscription();

    const { candidatePriceData, discountPriceData, onUpdateSubscription } = useSpecialOfferModal(
        cancellationCandidate!.external_id
    );

    if (!cancellationCandidate || !discountPriceData) {
        return null;
    }

    useEffect(() => {
        cancelFlow_cancelOfferScreenLoad({
            offer_type: OfferTypes.Discount_offer,
            subscription_id: cancellationCandidate.external_id,
        });
    }, []);

    const handleCancel = () => {
        onCancelSubscription();

        cancelFlow_cancelOfferClick({
            action: 'cancel',
            subscription_id: cancellationCandidate.external_id,
            offer_type: OfferTypes.Discount_offer,
        });
    };

    const handleClose = () => {
        closeModal();
        discardCancellation();

        cancelFlow_cancelOfferClick({
            action: 'close',
            subscription_id: cancellationCandidate.external_id,
            offer_type: OfferTypes.Discount_offer,
        });
    };

    const handleUpdate = () => {
        onUpdateSubscription();

        cancelFlow_cancelOfferClick({
            action: 'claim_offer',
            subscription_id: cancellationCandidate.external_id,
            offer_type: OfferTypes.Discount_offer,
        });
    };

    const goBack = () => {
        dispatch(openModal(ModalName.REASON_MODAL));
    };

    return (
        <Modal isOpen fullscreen>
            <>
                <ModalHeader onBack={goBack} onClose={handleClose} />
                <S.ModalContainer data-locator="discountOfferContainer">
                    <Box paddingX={25}>
                        <Text
                            text={t('subscription.cancellation.specialOffer.withDynamicPercent.title', { percent: 50 })}
                            type="h4"
                            medium
                            center
                            mb={12}
                        />
                        <Text
                            text={t('subscription.cancellation.specialOffer.withDynamicPercent.subtitle')}
                            type="large-text"
                            center
                            mb={24}
                        />
                    </Box>
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="text-secondary"
                        badgeText="subscription.cancellation.specialOffer.firstBadge"
                        price={candidatePriceData!.price}
                        period={candidatePriceData!.period}
                        currencySign={candidatePriceData!.currency}
                    />
                    <DiscountOffer
                        mb={24}
                        badgeBackgroundColor="critical-default"
                        badgeText="subscription.cancellation.specialOffer.withDynamicPercent.secondBadge"
                        badgePercent={50}
                        isNewOffer
                        oldPrice={candidatePriceData!.price}
                        price={discountPriceData.price}
                        period={discountPriceData.period}
                        currencySign={discountPriceData.currency}
                        onSubmitDiscount={handleUpdate}
                    />
                    <OutlinedButton
                        dataLocator="discountOfferCancelSubscriptionButton"
                        text={t('subscription.text.cancel')}
                        onClick={handleCancel}
                    />
                </S.ModalContainer>
            </>
        </Modal>
    );
};

export default SpecialOfferModalContainer;
