import { useCallback, useState } from 'react';

import { sendFeedback } from 'store/subscriptions/actions';

import { useCancelSubscription, useDispatch, useModals } from 'hooks/index';

import { FeedbackFormValues, PaymentProviders } from 'types/subscription';
import { ModalName } from 'types/modals';

const useFeedbackSubmission = () => {
    const dispatch = useDispatch();
    const { openModal } = useModals();
    const [isSubmitting, setSubmitting] = useState(false);
    const { cancellationCandidate } = useCancelSubscription();

    const onFeedbackRequested = () => {
        setSubmitting(false);

        if (cancellationCandidate?.payment_provider === PaymentProviders.Inary) {
            openModal(ModalName.FREE_MONTH_MODAL);
        } else {
            openModal(ModalName.SPECIAL_OFFER);
        }
    };

    const submitFeedback = useCallback(async (data: FeedbackFormValues) => {
        setSubmitting(true);
        dispatch(
            sendFeedback({
                data,
                onSuccess: onFeedbackRequested,
                onError: onFeedbackRequested,
            })
        );
    }, []);

    return { isSubmitting, submitFeedback };
};

export default useFeedbackSubmission;
