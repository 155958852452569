import { Main } from 'wikr-core-analytics';

export const sendAnalyticFreezeAlertLoad = () => {
    Main.customData('freeze_sub__screen__load', { event_action: 'popup__load' });
};

export const sendAnalyticFreezeReasonClick = (reason: string) => {
    Main.customData('freeze_sub__click', { event_action: 'button__quiz_continue', event_label: reason });
};

export const sendAnalyticFreezePeriodClick = (period: string) => {
    Main.customData('freeze_sub__click', { event_action: 'button__continue', event_label: period });
};
