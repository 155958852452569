import React from 'react';

import { openModal } from 'store/modals/actions';

import { sendAnalyticFreezeReasonClick } from 'services/analytics/trackers/subscriptions';

import { useCancelSubscription, useDispatch, useModals } from 'hooks';

import CancellationReasonModal from 'modals/CancellationReasonModal';

import { ModalName } from 'types/modals';

const FreezeReasonModalContainer = () => {
    const dispatch = useDispatch();
    const { closeModal } = useModals();

    const { discardCancellation, onCancelSubscription } = useCancelSubscription();

    const onSubmit = (reason: string) => {
        sendAnalyticFreezeReasonClick(reason);

        dispatch(openModal(ModalName.FREEZE_PERIOD_MODAL));
    };

    const onCloseHandler = () => {
        closeModal();
        discardCancellation();
    };

    return (
        <CancellationReasonModal
            onSubmit={onSubmit}
            onCancel={onCancelSubscription}
            onClose={onCloseHandler}
            isFreezeSubscription
        />
    );
};

export default FreezeReasonModalContainer;
