import { createGlobalStyle } from 'styled-components';

import { Theme } from 'components/library';

import resetStyles from './styles/reset';
import { color } from './selectors';

const GlobalStyle = createGlobalStyle<{ rootVariables: string; theme: Theme }>`
    :root {
        ${({ rootVariables }) => rootVariables}
    }
    
    body {
        background: ${color('surface-main')};

        &.ReactModal__Body--open {
            overflow: hidden;
        }
    }

    .text-right {
        text-align: right;
    }

    .text-underline {
        text-decoration: underline;
    }

    ${resetStyles}

`;

export default GlobalStyle;
