import { DATE_FORMAT_COMMON, DATE_FORMAT_AMERICAN_TYPE, DATE_FORMAT_ASIAN_TYPE } from 'constants/dateFormats';

import dayjs from 'dayjs';

const AMERICAN_TYPE_LIST = ['US', 'CA', 'GH', 'ZA', 'KE'];
const ASIAN_TYPE_LIST = ['CN', 'JP', 'KP', 'KR', 'TW', 'HU', 'BT'];

export const getBirthdayByAge = (age: number): string => {
    const currentYear = new Date().getFullYear();

    return `${currentYear - age}-01-01`;
};

export const getAgeFromBirthday = (birthday?: string): number => {
    if (!birthday) return 0;

    const today = new Date();
    const todayYear = today.getFullYear();
    const yearOfBirth = birthday.split('-')[0];

    return todayYear - Number(yearOfBirth);
};

export const getFormattedDate = (date: string, format: string): string => {
    return dayjs(date).format(format);
};

const getDateFormatDependsOnCountry = (country?: string): string => {
    if (country && AMERICAN_TYPE_LIST.includes(country)) {
        return DATE_FORMAT_AMERICAN_TYPE;
    }

    if (country && ASIAN_TYPE_LIST.includes(country)) {
        return DATE_FORMAT_ASIAN_TYPE;
    }

    return DATE_FORMAT_COMMON;
};

export const getFormattedDateByCountry = (date: string, country?: string) => {
    const format = getDateFormatDependsOnCountry(country);

    return getFormattedDate(date, format);
};
