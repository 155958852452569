export const SUBSCRIPTION_TITLE = {
    APP_FULL_ACCESS: 'subscription.heading.personalizedProgram',
    APP_VIP_SUPPORT: 'subscription.heading.vipSupport',
    APP_WELLCUBE: 'subscription.heading.wellcube',
    OTHER: 'subscription.heading.subscription',
};

export enum DISCOUNT_TYPES {
    base = 'base',
    extra = 'extra',
}
