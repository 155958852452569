import { Colors, Text, Image, Box, Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import starIcon from './img/starIcon.webp';

interface IPauseOfferProps {
    badgeText: string;
    badgeBackgroundColor: Colors;
    mb: number;
    onPauseSubscription: () => void;
}

const pauseInfoList = [
    'subscription.cancellation.pauseOffer.pauseInfo1',
    'subscription.cancellation.pauseOffer.pauseInfo2',
    'subscription.cancellation.pauseOffer.pauseInfo3',
];

const PauseOffer = ({ badgeText, badgeBackgroundColor, mb, onPauseSubscription }: IPauseOfferProps) => {
    const { t } = useTranslation();

    return (
        <S.Container mb={mb}>
            <S.Badge backgroundColor={badgeBackgroundColor}>
                <Text text={t(badgeText)} color="on-primary" medium center />
            </S.Badge>
            <S.Details paddingTop={20} paddingX={16} paddingBottom={16} backgroundColor="surface-secondary">
                <Box mb={24}>
                    {pauseInfoList.map((pauseInfoText) => (
                        <S.InfoBlock key={pauseInfoText}>
                            <Image alt="icon" src={starIcon} maxWidth={16} />
                            <Text text={t(pauseInfoText)} />
                        </S.InfoBlock>
                    ))}
                </Box>
                <Button
                    onClick={() => onPauseSubscription()}
                    text={t('subscription.cancellation.pauseOffer.pauseBtnText')}
                    dataLocator="pauseOfferPauseSubscriptionButton"
                />
            </S.Details>
        </S.Container>
    );
};

export default PauseOffer;
