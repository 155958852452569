import { useTranslation } from 'react-i18next';
import React from 'react';

import WaitingModal from 'modals/WaitingModal';

import { WaitingModalContainerProps } from './types';

const WaitingModalContainer = ({ title }: WaitingModalContainerProps = {}) => {
    const { t } = useTranslation();

    return (
        <WaitingModal
            captions={{ title: t(title ?? 'subscription.cancelDialog.loading') }}
            dataLocator="cancellProgressModal"
        />
    );
};

export default WaitingModalContainer;
