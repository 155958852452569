import { customTrackDecorator, trackDecoratorWithExtendedLabel } from './mainTrackers';

export enum OfferTypes {
    Discount_offer = 'Discount offer',
    Pause_offer = 'Pause offer',
    Free_month = 'Free month',
    Return_offer = 'Return offer',
    Reminder_offer = 'Reminder offer',
    Fee_offer = 'Fee',
}

// https://docs.google.com/spreadsheets/d/1bMsv6b7lYE-VLNaRRLsQNlcjGy8x2ERfWxYvLZMom54/edit?gid=0#gid=0

/**
 * 1. Screen load on the subscription tab
 */
export const cancelFlow_subscriptionScreenLoad = ({
    reminder,
    free_month,
    subscriptions_status,
}: {
    reminder: boolean;
    free_month: boolean;
    subscriptions_status: { [id: string]: string }[];
}) => {
    trackDecoratorWithExtendedLabel('account_subscription__screen__load', undefined, {
        reminder,
        free_month,
        subscriptions_status,
    });
};

/**
 * 2. Click on the Cancel button on  the subscription card
 * 3. Click on the Resume button on  the subscription card
 */
export const cancelFlow_subscriptionClick = ({
    action,
    status,
    reminder,
    free_month,
    subscription_id,
}: {
    action: 'cancel' | 'resume';
    status: string;
    reminder: boolean;
    free_month: boolean;
    subscription_id: string;
}) => {
    trackDecoratorWithExtendedLabel(
        'account_subscription__click',
        action,
        { sub_status: status, reminder, free_month },
        { subscription_id }
    );
};

/**
 * 4. Screen load for the reasons modal
 */
export const cancelFlow_reasonsScreenLoad = ({ subscription_id }: { subscription_id: string }) => {
    trackDecoratorWithExtendedLabel('cancel_subscription_reason__screen__load', undefined, undefined, {
        subscription_id,
    });
};

/**
 * 5. Click on the Continue button on the survey with predicted offer_type will be shown
 * 6. Click on the Cancel button - // -
 * 7. Click on the "X" button on - // -
 */
export const cancelFlow_reasonsContinueClick = ({
    action,
    reason,
    offer_type,
    subscription_id,
}: {
    action: 'continue' | 'cancel' | 'close';
    reason?: string;
    offer_type?: OfferTypes;
    subscription_id?: string;
}) => {
    trackDecoratorWithExtendedLabel(
        'cancel_subscription_reason__click',
        action,
        { reason, offer_type },
        { subscription_id }
    );
};

/**
 * 8. Screen load for other screen with predicted offer_type will be shown
 */
export const cancelFlow_otherReasonScreenLoad = ({
    offer_id,
    offer_type,
    subscription_id,
}: {
    offer_id?: string;
    offer_type: OfferTypes;
    subscription_id: string;
}) => {
    trackDecoratorWithExtendedLabel(
        'cancel_other_reason__screen__load',
        undefined,
        { offer_id, offer_type },
        { subscription_id }
    );
};

/**
 * 9. Click on the Continue button on the feedback form with predicted offer_type will be shown
 * 10. Click on the Cancel button - // -
 * 11. Click on the "X" button - // -
 */
export const cancelFlow_otherReasonClick = ({
    action,
    comment,
    offer_id,
    offer_type,
    subscription_id,
}: {
    action: 'continue' | 'cancel' | 'close';
    comment?: string;
    offer_id?: string;
    offer_type?: OfferTypes;
    subscription_id: string;
}) => {
    trackDecoratorWithExtendedLabel(
        'cancel_other_reason__click',
        action,
        { comment, offer_id, offer_type },
        { subscription_id }
    );
};

/**
 * (12-17). Screen load for each OfferTypes
 */
export const cancelFlow_cancelOfferScreenLoad = ({
    offer_id,
    offer_type,
    subscription_id,
    fee_ammount,
}: {
    offer_id?: string;
    offer_type: OfferTypes;
    subscription_id: string;
    fee_ammount?: number;
}) => {
    trackDecoratorWithExtendedLabel(
        'cancel_offer__screen__load',
        undefined,
        { offer_id, offer_type, fee_ammount },
        { subscription_id }
    );
};

/**
 * (18-23). Cick for each OfferTypes buttons - Apply offer, Cancel, "X" (close modal)
 */
export const cancelFlow_cancelOfferClick = ({
    action,
    offer_id,
    offer_type,
    fee_amount,
    subscription_id,
}: {
    action: 'claim_offer' | 'cancel' | 'close';
    offer_type: OfferTypes;
    offer_id?: string;
    fee_amount?: number;
    subscription_id: string;
}) => {
    trackDecoratorWithExtendedLabel(
        'cancel_offer__click',
        action,
        { offer_id, offer_type, fee_amount },
        { subscription_id }
    );
};

/**
 * Rest: success & error modals
 */

export const cancelFlow_cancelResultScreenLoad = ({
    result,
    subscription_id,
}: {
    result: 'success' | 'fail';
    subscription_id: string;
}) => {
    customTrackDecorator('cancel_result__screen__load', undefined, result, { subscription_id });
};

export const cancelFlow_cancelResultClick = ({
    action,
    result,
    subscription_id,
}: {
    action: 'continue' | 'close' | 'try_again' | 'contact_support';
    result: 'success' | 'fail';
    subscription_id: string;
}) => {
    customTrackDecorator('cancel_result__click', action, result, { subscription_id });
};

export const cancelFlow_offerResultScreenLoad = ({
    result,
    offer_id,
    offer_type,
    subscription_id,
    fee_ammount,
    fee_result,
}: {
    result: 'success' | 'fail';
    offer_id?: string;
    offer_type: OfferTypes;
    subscription_id?: string;
    fee_ammount?: number;
    fee_result?: 'success' | 'fail';
}) => {
    trackDecoratorWithExtendedLabel(
        'offer_result__screen__load',
        undefined,
        { result, offer_id, offer_type, fee_ammount, fee_result },
        { subscription_id }
    );
};

export const cancelFlow_offerResultClick = ({
    action,
    result,
    offer_id,
    offer_type,
    fee_ammount,
    fee_result,
    subscription_id,
}: {
    action: 'continue' | 'close' | 'try_again' | 'contact_support';
    result: 'success' | 'fail';
    offer_id?: string;
    offer_type: OfferTypes;
    subscription_id?: string;
    fee_ammount?: number;
    fee_result?: 'success' | 'fail';
}) => {
    trackDecoratorWithExtendedLabel(
        'offer_result__click',
        action,
        { result, offer_id, offer_type, fee_ammount, fee_result },
        { subscription_id }
    );
};
