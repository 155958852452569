import { PRODUCT_CODES } from 'constants/payments';

import { Product, SubscriptionProductCodes, IUpcomingProduct } from './product';

type ProductCodeKeys = keyof typeof PRODUCT_CODES;
export type ProductCode = typeof PRODUCT_CODES[ProductCodeKeys];

export enum PaymentProviders {
    Inary = 'inary',
    Solidgate = 'solidgate',
}

export enum DiscountTypes {
    base = 'base',
    extra = 'extra',
}

export interface ICancelSubscriptionRequest {
    id: string;
    provider: PaymentProviders;
}

export interface IDiscounts {
    discount: string | null;
    pause: string | null;
    free_month: string | null;
    isDiscountOfferAvailable?: boolean;
}

export interface SubscriptionPause {
    pause_from: string | null;
    pause_to: string | null;
    pause_type: IPauseType;
}

export interface SubscriptionFee {
    fee_enabled: boolean;
    fee_type: 'annual';
    fee_rebill_left: number;
    fee_amount: number;
    fee_total_price: number;
    fee_expired_at: string;
}

export interface ISubscription {
    expired_date: string;
    external_id: string;
    discounts: IDiscounts;
    reminder: boolean;
    id: number;
    price: string;
    product: Product;
    subscription_period: number;
    subscription_trial_period: number | null;
    fee: SubscriptionFee;
    product_code: SubscriptionProductCodes;
    pause: SubscriptionPause;
    payment_provider: PaymentProviders;
    purchase_date: string;
    state: string;
    is_trial: boolean;
    cancelled_at: string;
    internal_cancel_at: string;
    upcoming_product: IUpcomingProduct | null;
    period: number;
    trial_period: number;
}

export interface IDiscountSubscription {
    id: string;
    name: string;
    amount: number;
    currency: string;
    trial: boolean;
    subscription_period: number;
    subscription_trial_period: null | number;
    started_from: string;
    product_id: string;
}

export interface IDiscountSubscriptionUpdate {
    updatedSubscription: IUpdateSubscription;
    onSuccess: () => void;
    onError: () => void;
}

export interface IResetSubscription {
    external_id: string;
    product: IDiscountSubscription;
    expired_at?: string;
}

export interface IUpdateSubscription {
    external_id: string;
    product: IDiscountSubscription;
}

export interface IRestoreSubscription {
    external_id: string;
    provider: PaymentProviders;
}

export interface FeedbackFormValues {
    reason: string;
    comment?: string;
}

export type IPauseType = 'pause' | 'free_month';
